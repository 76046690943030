
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@700&family=Roboto&family=Source+Sans+Pro:wght@400;700&display=swap');* {   
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
html {
  font-family: "Montserrat";
}
section {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}
.container {
  width: 100%;
  height:100%;
  max-width: 100%;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: aqua; */
}
.left {
  width: 50%;
  height: 600px;
  margin: 0px;
  background: url('https://blog.myfitnesspal.com/wp-content/uploads/2019/05/5-Benefits-of-Shopping-at-Your-Local-Farmers-Market.jpg')
    no-repeat center / cover;
  border-radius: 0px;
}
.right {
  width: 50%;
  min-height: 400px;
  background-color: #8ECA9F;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border-radius: 8px;
  color: white;
  margin-left: -150px;
  box-shadow: 20px 20px 0px #cb89e5;
  
}

.right h1 {
  font-size: 40px;
  font-weight: 400;
}
.right p {
  margin: 20px 0;
  font-weight: 500;
  line-height: 26px;
  font-size:20px;
}
.right a {
  text-decoration: none;
  
  background-color: white;
  color: black;
  padding: 10px 30px;
  display: inline-block;
  letter-spacing: 2px;
  line-height: 15px;
  border-radius: 8px;
  font-family: "IBM Plex Serif";
  font-size: 22px;
  

}
.right a p {
  text-decoration: none;
  text-transform: uppercase;
  background-color: white;
  color: black;
  padding: 0px 5px;
  display: inline-block;
  letter-spacing: 2px;
  line-height: 15px;
  border-radius: 8px;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 18px;

}
@media only screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    width: 100%;
    margin: 0 20px;
  }
  .left {
    width: 100%;
    height: 400px;
  }
  .right {
    width: 90%;
    margin: 0;
    margin-top: 0px;
  }
}


/* 

.wrapper {
        display: grid;
      
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 150px 150px 150px;       
        grid-template-areas:
              "side  top"
              "side  middle"
              "side  bottom"
              
      }
 */

